<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div :class="auditStatus=== '00'? 'active' : 'none'" @click="btnClick('00')">
            全部({{ numView.allCount || 0 }})
          </div>
          <div :class="auditStatus=== '01'? 'active' : 'none'" @click="btnClick('01')">
            待审核({{ numView.toAuditCount || 0 }})
          </div>
          <div :class="auditStatus=== '02'? 'active' : 'none'" @click="btnClick('02')">
            已通过({{ numView.passAuditCount || 0 }})
          </div>
          <div :class="auditStatus=== '03'? 'active' : 'none'" @click="btnClick('03')">
            已驳回({{ numView.refuseAuditCount || 0 }})
          </div>
        </div>
      </div>
      <el-button type="warning" icon="el-icon-plus" size="small" @click="applyForShow">添加物流申请</el-button>
    </div>
    <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :data="this.seachDataList"
        :currentPage="page.pageNum"
        :total="page.total"
        @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
          label="物流设置"
          slot="logisticsSet"
          align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="(row.isLogisticsConfig || row.auditStatus === '02')" style="color: #0981ff;cursor: pointer;"
               @click="seeLogisticsSet(row)">设置
          </div>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
      <el-table-column
          label="月结账号"
          slot="monthSettlementAccount"
          align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.monthSettlementAccount || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="审核状态"
          slot="auditStatus"
          align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.auditStatus == '01' ? '待审核' : row.auditStatus == '02' ? '已通过' : '已驳回' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="合作商家"
          slot="companyNum"
          align="center"
      >
        <template slot-scope="{ row }">
          <span @click="seeBusiness(row)"
                style="color: #0981ff;cursor: pointer;border-bottom: 1px solid #0981ff">{{
              row.companyNum
            }}/已启用物流{{ row.setCompanyNum }}家</span>
          <!-- 添加物流申请和批量开通物流申请差不多，使用同一个dialog，动态改变state  -->
          <p v-if="row.auditStatus === '02'" style="color: #F59A31;cursor: pointer" @click="addCooperateShow(row)">
            添加</p>
        </template>
      </el-table-column>
      <el-table-column
          label="物流开启/关闭"
          slot="isEnable"
          align="center"
      >
        <template slot-scope="{ row }">
          <el-switch
              inactive-color="#bebebe"
              @change="handleSwitch(row)"
              v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
          label="备注"
          width="130rpx"
          slot="note"
          align="center"
      >
        <template slot-scope="{ row }">
          <el-tooltip
              class="item"
              effect="dark"
              v-if="row.masterNote"
              :content="row.masterNote"
              placement="top"
          >
            <div class="Remarks">
              {{ row.masterNote }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" :type="row.auditStatus=='01'?'primary':'warning'" round
                     @click="handleBtn(row,'apply')">{{ row.auditStatus == '01' ? '审核' : '审核记录' }}
          </el-button>
          <el-button size="mini" type="success" round @click="handleBtn(row,'remark')">
            {{ row.masterNote ? '修改备注' : '添加备注' }}
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog
        class="acc_dialog"
        :title='RemarksTitle'
        :visible.sync="RemarksShow"
        :close-on-click-modal="false"
        width="410px"
        @closed="remarksClosed"
    >
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input
          maxlength="100"
          type="textarea"
          show-word-limit
          :rows="4"
          placeholder="请输入备注内容,最多100字"
          v-model="masterNote"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog
        title="查看物流设置"
        :visible.sync="setDialogVisible"
        :close-on-click-modal="false"
        width="458px"
    >
      <div class="verify-title"><span>商家信息</span></div>
      <div class="mb-20" style="font-size:14px;color:#333333">
        <div class="mb-10">商家名称：<span style="color:#666666;">{{ rulesInfo.merchantName }}</span></div>
        <div>联系人：<span style="color:#666666;">{{ rulesInfo.contact }}</span><span
            style="margin-left:20px">联系电话：</span><span style="color:#666666;">{{ rulesInfo.phone }}</span></div>
      </div>
      <div class="verify-title"><span>快递选择</span></div>
      <el-radio-group v-model="setingData.firstExpressCode">
        <div class="flex mb-20" style="font-size:14px;color:#333333">
          <el-radio style="margin-right: 0px" :label="expressList.length?expressList[0].expressCode:undefined">&nbsp;
          </el-radio>
          <div class="mr-50">首选：<span
              style="color:#666666;">{{ expressList.length ? expressList[0].expressName : '--' }}</span></div>
          <div>次选：<span style="color:#666666;">{{ expressList.length ? expressList[1].expressName : '--' }}</span>
          </div>
        </div>
        <div class="flex mb-20" style="font-size:14px;color:#333333">
          <el-radio style="margin-right: 0px" :label="expressList.length?expressList[1].expressCode:''">&nbsp;
          </el-radio>
          <div class="mr-50">首选：<span
              style="color:#666666;">{{ expressList.length ? expressList[1].expressName : '--' }}</span></div>
          <div>次选：<span style="color:#666666;">{{ expressList.length ? expressList[0].expressName : '--' }}</span>
          </div>
        </div>
      </el-radio-group>
      <div class="verify-title"><span>发货价值设置</span></div>
      <div class="mb-20" style="font-size:14px;color:#333333">
        <div><span style="color:#666666;">旧机总报价≥</span><span
            class="ml-10 mr-10 font-w">
          <el-input
              maxlength="5"
              placeholder="请输入"
              style="width: 100px;"
              :value="setingData.minCostSend"
              @input="minCostSendInput"
              clearable>
</el-input>
        </span><span
            style="color:#666666;">元门店可发货一次</span></div>
      </div>
      <div class="verify-title"><span>发货规则</span></div>
      <div class="mb-20" style="font-size:14px;color:#333333">
        <div class="mb-10">发货订单：<span style="color:#666666;">当日发货前一日及以前成交订单</span></div>
        <div class="mb-10 flex flex-as">
          <div>发货提醒规则：</div>
          <div style="color:#666666;">
            <div>济南繁星回收门店端公众号消息推送</div>
            <div><span style="color:#FF687B;">次日8:30,9:30,10:30,16:30</span>发送消息提醒</div>
            <div><span style="color:#0981FF;">次月2号</span>再次推送上月未发货订单消息提醒</div>
          </div>
        </div>
        <div>发货提醒人员：<span style="color:#666666;">门店店长、下单店员</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setDialogClose">取 消</el-button>
        <el-button type="primary" @click="setingConfig">确 定</el-button>
      </span>
    </el-dialog>
    <DialogTbale
        :show="businessVisible"
        title="合作商家"
        :loading="businessLoading"
        :columns="DialogTableColumns"
        :data="DialogDataList"
        :currentPage="dialogPage.pageNum"
        :total="dialogPage.total"
        :pageSize="dialogPage.pageSize"
        :width="900"
        :isSelection="true"
        :isIndex="true"
        @onClose="onClose"
        @handleSelectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChangeDialog"
    >
      <div class=" mb-20">
         <div class="flex">
            <div class="flex">
            <span style="width: 92px">商家名称：</span>
            <el-input
                v-model="companyName"
                size="small"
                clearable
                placeholder="请输入商家名称查询"
            ></el-input>
            </div>
            <el-button
                type="primary"
                style="margin-left: 10px"
                size="small"
                @click="searchSetting"
            >
              查询
            </el-button>
         </div>
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <div class="faultBtn" style="margin-top: 20px;width: 200px;">
              <div :class="isLogisticsConfig? 'active' : 'none'" @click="qiClick(true)">
                已启用
              </div>
              <div :class="!isLogisticsConfig? 'active' : 'none'" @click="qiClick(false)">
                已禁用
              </div>
            </div>
            <el-button :disabled="logMerchantList.length==0" @click="batchAction" size="small" v-if="isLogisticsConfig" type="danger">批量禁用</el-button>
            <el-button :disabled="logMerchantList.length==0" @click="batchAction" v-else size="small" type="success">批量启用</el-button>
          </div>
      </div>
      <el-table-column label="合作状态" slot="companyIsEnable" align="center">
        <template slot-scope="{ row }">
          <span>{{
              row.applyStatus == '00' ? '邀请待同意' : row.applyStatus == '01' ? '合作中' : row.applyStatus == '02' ? '邀请未通过' : '取消合作'
            }}{{
              row.companyIsEnable == '02' && row.applyStatus != '00' && row.applyStatus != '02' ? '(关闭报价)' : ''
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="物流状态" slot="isLogisticsConfig" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.isLogisticsConfig ? '已启用' : '未启用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最新开通时间" slot="openLogisticsTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.openLogisticsTime || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用/关闭物流" slot="switchAction" align="center" v-if="currentData.auditStatus === '02'">
        <template v-slot="{ row }">
          <div class="enable_box">
            <el-switch
                :disabled="row.isOpenNiceBiz && row.storeSendSet === '00'"
                :value="row.isLogisticsConfig"
                @change="(v)=>companyIsEnableChange(v,row)"
                active-text="开"
                inactive-text="关">
            </el-switch>
          </div>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-dialog
        :title='enableTitle'
        :visible.sync="enableShow"
        :close-on-click-modal="false"
        width="400px"
        @closed="enableClosed"
    >
      <div class="enable-tip">
        <div>
          {{
            isEnable ? '提示：开启后，该商家合作门店可通过线上物流进行发货。' : '提示：该商家不再使用繁星物流发货，该操作第二天生效，今日待发货订单仍可使用繁星物流发货。'
          }}
        </div>
        <div class="tip-sure">{{ isEnable ? '是否确认启用该商家物流功能？' : '是否确认关闭该商家物流功能？' }}</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input
            v-model="command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableCancel">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="enableSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="applyTitle"
        :visible.sync="applyDialogVisible"
        :close-on-click-modal="false"
        width="458px"
        @closed="applyDialogClose"
    >
      <div v-if="applyTitle!='审核记录'">
        <div class="verify-title"><span>审核结果</span></div>
        <div class="mb-20">
          <div class="mb-10">
            <el-radio v-model="auditStatusRadio" label="02">审核通过</el-radio>
            <el-radio v-model="auditStatusRadio" label="03">审核驳回</el-radio>
          </div>
          <div class="command" v-if="auditStatusRadio=='02'">
            <div style="width: 185px;">月结账号<span style="color:#FF687B;">（必填）</span>：</div>
            <el-input
                size="small"
                maxlength="10"
                @input="(val)=>monthSettlementAccount = val.replace(/[^\d]/g,'').replace(/^0$/,'')"
                v-model="monthSettlementAccount"
                placeholder="请输入物流月结账号(小于11位的正整数)"
            ></el-input>
          </div>
        </div>
        <div class="verify-title"><span>审核备注{{ auditStatusRadio == '03' ? '（必填）' : '（非必填）' }}</span></div>
        <div class="mb-20">
          <el-input maxlength="20" type="textarea" placeholder="请输入备注内容，最多20字" show-word-limit
                    v-model="auditNote"></el-input>
        </div>
        <div class="verify-title"><span>授权密码</span></div>
        <div class="command mb-20">
          <div style="width: 88px;">动态口令：</div>
          <el-input
              size="small"
              v-model="command"
              placeholder="请输入谷歌动态口令"
              oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </div>
      </div>
      <div v-else>
        <div class="verify-title"><span>商家信息</span></div>
        <div class="mb-20" style="font-size:14px;color:#333333">
          <div class="mb-10">商家名称：<span style="color:#666666;">{{ applyRecord.merchantName }}</span></div>
          <div>联系人：<span style="color:#666666;">{{ applyRecord.applyStaffName }}</span><span
              style="margin-left:20px">联系电话：</span><span
              style="color:#666666;">{{ applyRecord.applyStaffPhone }}</span></div>
        </div>
        <div class="verify-title"><span>审核结果</span></div>
        <div class="mb-20" style="color:#333333">
          {{ applyRecord.auditStatus == '02' ? '已通过审核' : applyRecord.auditStatus == '03' ? '已驳回' : '' }}
        </div>
        <div class="verify-title"><span>审核备注</span></div>
        <div class="mb-20">{{ applyRecord.auditNote || '无' }}</div>
        <div class="verify-title"><span>日志记录</span></div>
        <div class="mb-20"><span>审核人：</span><span
            style="color:#666666;">{{ applyRecord.auditMasterName }}</span><span
            style="margin-left:20px">审核时间：</span><span style="color:#666666;">{{ applyRecord.auditTime }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="applyDialogClose">关闭</el-button>
        <el-button type="primary" v-if="applyTitle!='审核记录'" @click.native="applyHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <div class="apply_for_box">
      <!-- 物流申请和合作商家弹窗，接口一致，用一个创建封装 -->
      <DialogTableSearch v-bind="applyForProps"
                         @close="applyForProps.show = false;applyForProps.isResetForm = false;"
                         @config="applyForConfig"
                         @handleConfirm="(v)=>applyForSearch(v)"
                         @sizeChange="(v)=>{applyForProps = {...applyForProps,...v};getApplyForData();}"
                         @pageChange="(v)=>{applyForProps={...applyForProps,...v};getApplyForData();}"
                         @handleSelect="(v,row)=>cooperaSelect(v,row)"
                         @handleSelectAll="(v)=>cooperaSelectAll(v)"
      >
        <template v-slot:header>
          <p v-if="applyForProps.isApplyFor" class="lv_fc_red apply_for_tip">
            说明：创建后请在待审核申请下审核并开通物流</p>
        </template>
        <template v-slot="{row,prop}">
          <div v-if="prop === 'select'">
            <el-checkbox :value="row.merchantId === applyForProps.selectId"
                         @change="applyForProps.selectId = row.merchantId"/>
          </div>
          <div v-if="prop === 'address'">
            <el-tooltip class="item" effect="dark" :content="row[prop]" placement="top">
              <p style="width: 100%;" class="lv_ycyc2">{{ row[prop] }}</p>
            </el-tooltip>
          </div>
          <div v-else>
            {{ row[prop] }}
          </div>
        </template>
      </DialogTableSearch>
    </div>


    <div class="logistics-switch-dialog">
      <DialogAction v-bind="logisticsSwitchProps"
                   :isTip="false"
                    @close="logisticsSwitchProps.show = false;handleCurrentChangeDialog()"
                    @config="logisticsSwitchConfig"
      >
        <template v-slot:headerMessage>
          <i class="el-icon-warning-outline"></i>
        </template>
        <template v-slot:from>
          <div  class="tip" v-if="isbatch">
            是否确认{{!isLogisticsConfig?'启用':'禁用'}}所选<span>{{logMerchantList.length}}个</span>门店商户的线上物流功能？
           </div>
           <div  class="tip" v-else>
            是否确认{{islistLogistics?'启用':'禁用'}}所选<span>{{listLogisticData.companyName}}</span>商户的线上物流功能？
           </div>
        </template>
      </DialogAction>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import DialogTableSearch from "@/components/common/DialogTableSearch.vue";
import {
  applyForProps,
  applyForTableColumns,
  cooperaTableColumns,
  logisticsSwitchProps
} from "@/views/logisticsManage/apply/initState";
import {getMeta, getNumberValue, pageSize, setValue} from "@/utils/common";
import DialogAction from "@/components/common/DialogAction.vue";

export default {
  name: "Table",
  components: {DialogAction, DialogTableSearch},
  data() {
    return {
      // 启用禁用物流信息提示
      logisticsSwitchProps,
      setingData: {
        firstExpressCode: "",
        merchantId: null,
        minCostSend: 0
      },
      applyForProps,
      loading: false,
      seachDataList: [],
      auditStatus: '00',
      auditStatusRadio: "",
      butLoading: false,
      enableButLoading: false,
      numView: {},
      page: {
        pageNum: 1,
        pageSize: 10
      },
      tableColumns: [
        {label: "申请商家", prop: "merchantName"},
        {label: "申请人", prop: "contact"},
        {label: "联系电话", prop: "phone"},
        {label: "月均包裹量（个）", prop: "monthPackCount"},
        {label: "月均快递费（元）", prop: "avgMonthExpressFee"},
        {slotName: "logisticsSet"},
        {label: "申请时间", prop: "applyTime"},
        {slotName: "monthSettlementAccount"},
        {slotName: "auditStatus"},
        {slotName: "companyNum"},
        {slotName: "isEnable"},
        {slotName: "note"},
        {slotName: "operation"}
      ],
      // 弹框
      RemarksTitle: "",
      applyTitle: "",
      command: "",
      auditNote: "",
      monthSettlementAccount: "",
      RemarksShow: false,
      enableShow: false,
      applyDialogVisible: false,
      enableTitle: "",
      masterNote: "",
      setDialogVisible: false,
      rulesInfo: {},
      expressList: [],
      businessVisible: false,
      DialogTableColumns: [
        {label: "门店商家", prop: "companyName"},
        {label: "联系人", prop: "contact"},
        {label: "联系电话", prop: "phone"},
        {slotName: "companyIsEnable"},
        {slotName: "isLogisticsConfig"},
        {slotName: "openLogisticsTime"},
        {
          slotName: "switchAction"
        }
      ],
      DialogDataList: [],
      dialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isEnable: "",
      applyRecord: {},
      businessLoading: false,
      companyName: "",
      isLogisticsConfig: true,
      islistLogistics: false,
      listLogisticData: {},
      isbatch:false,
      isLogisticsConfigList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      currentData: {},
      logMerchantList:[]
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
  },
  methods: {
    //合作商家
    handleSelectionChange(e){
     console.log(e)
     this.logMerchantList=e
    },
    // 输入变化
    minCostSendInput(v) {
      this.setingData = {
        ...this.setingData,
        minCostSend: getNumberValue(v)
      }
    },
    // 设置最低价和物流配送方式
    setingConfig() {
      const {firstExpressCode, minCostSend} = this.setingData;
      if (!firstExpressCode) {
        return this.$message.error("请选择快递优先级");
      }
      if (!minCostSend) {
        return this.$message.error("请设置最小发货价值且不能为0")
      }

      _api.logisticsSaveConfigInfo(this.setingData).then(r => {
        this.$message.success("操作成功");
        this.handleCurrentChange();
        this.setDialogVisible = false;
      })
    },
    // 未申请物流回收商列表搜索
    applyForSearch(v) {
      this.applyForProps.params = {
        ...this.applyForProps.params,
        ...v,
        pageNum: 1,
      };
      this.applyForProps.loading = true,
          this.getApplyForData();
    },
    // 获取物流未申请物流回收商列表或者未合作商家列表
    // v为true代表获取物流回收商未启用列表，反之获取合作商家未启用列表
    getApplyForData() {
      this.applyForProps = {
        ...this.applyForProps,
        loading: true,
        tabData: []
      }
      let request = null;
      const v = this.applyForProps.isApplyFor;
      if (v) {
        request = _api.noOpenMerchantList;
      } else {
        request = _api.getUnionCompanyList;
      }
      request(this.applyForProps.params).then(r => {
        this.applyForProps.meta = getMeta(r.data.total, r.data.current, r.data.size);
        const data = r.data || {};
        let records = data.records || [];
        // 单独为合作回收商列表添加地址操作
        if (!v) {
          records = records.map(v => {
            const address = v.orderAddress ? (v.orderAddress.merchantAddress || '--') : '--'
            return {
              ...v,
              address,
            }
          })
        }
        // 设置值
        this.applyForProps = {
          ...this.applyForProps,
          tabData: records.map(v => setValue(v)),
          loading: false
        };
      });
    },
    // 物流弹窗显示操作
    applyForShow() {
      // 重赋值
      this.applyForProps = {
        ...this.applyForProps,
        show: true,
        tabData: [],
        params: {
          pageNum: 1,
          pageSize,
          merchantName: null
        },
        // 是否保留，保留就去除赋值
        selectId:null,
        // 禁用操作
        selectable: (v) => true,
        loading: true,
        title: "创建物流申请",
        isApplyFor: true,
        isResetForm: true,
        isSearch: true,
        isTabSelection: false,
        tableColumns: applyForTableColumns
      }
      // 获取数据
      this.getApplyForData();
    },
    // 添加合作商家
    addCooperateShow(row) {
      const {currentData, allSelectData} = this.applyForProps;
      // 去除单选，改成多选
      this.applyForProps = {
        ...this.applyForProps,
        title: "批量申请物流",
        show: true,
        tabData: [],
        selectable: (v) => {
          // null|undefined值，默认不是靓机商
          v.isOpenNiceBiz = v.isOpenNiceBiz ?? false;
          // 是靓机商家且发往靓机回收商禁用
          return !(v.isOpenNiceBiz && v.storeSendSet === '00')
        },
        params: {
          pageNum: 1,
          pageSize,
          isLogisticsConfig: false,
          id: row.id,
        },
        // currentData.id === row.id ? allSelectData :[]
        allSelectData:  [],
        isSearch: false,
        currentData: row,
        isResetForm: true,
        isTabSelection: true,
        tableColumns: cooperaTableColumns,
        isApplyFor: false,
        loading: true
      }
      // 合作商家未启用
      this.getApplyForData(false);
    },
    // 物流申请和合作商申请操作确定操作
    applyForConfig(v) {
      const {selectId, allSelectData, isApplyFor, currentData} = this.applyForProps;
      let request;
      if (isApplyFor) {
        if (!selectId && selectId !== 0) {
          return this.$message.error("请选择申请开通物流的回收商");
        }
        _api.logisticsApply(selectId).then(r => {
          this.$message.success("申请成功");
          this.applyForProps = {
            ...this.applyForProps,
            show: false,
            selectId: null,
          }
          this.handleCurrentChange(1);
        })
      } else {
        const companyIds = allSelectData.map(v => v.companyId);
        const merchantId = currentData.merchantId;
        if (!companyIds.length) {
          return this.$message.error("请选择商家");
        }
        _api.addLogisticsCompany({merchantId, companyIds}).then(r => {
          this.applyForProps = {
            ...this.applyForProps,
            show: false,
            allSelectData: [],
          }
          this.handleCurrentChange();
        })
      }
    },
    // 是否已存在
    getResetData(v, isDelete = false) {
      v = v.filter(v => v);//先过滤element翻页选择时内置多余undefined
      const allSelectData = this.applyForProps.allSelectData;
      // 去重后的data
      let resetData = [];
      v.forEach(item => {
        const index = allSelectData.findIndex(obj => obj.companyId === item.companyId)
        if (index < 0 && !isDelete) {
          resetData.push(item);
        }
        // 全不选时，删除数据
        if (isDelete && index >= 0) {
          allSelectData.splice(index, 1);
        }
      });
      return [...allSelectData, ...resetData];
    },
    // 当前数据单个过滤
    currentFilterate(current) {
      this.applyForProps.allSelectData = this.applyForProps.allSelectData.filter(v => v.companyId !== current.companyId);
    },
    // 单读选则
    cooperaSelect(v, row) {
      v = v.filter(v => v);
      // 勾选
      // 当前点击项是否选择
      const index = v.findIndex(v => v.companyId === row.id);
      // 不存在
      if (index < 0) {
        // 直接过滤
        this.currentFilterate(row);
      }
      this.applyForProps.allSelectData = this.getResetData(v);
    },
    // 多选
    cooperaSelectAll(v) {
      v = v.filter(v => v);
      // 全选
      if (v.length) {
        return this.applyForProps.allSelectData = this.getResetData(v);
      }
      // 全不选
      if (!v.length) {
        return this.applyForProps.allSelectData = this.getResetData(this.applyForProps.tabData, true);
      }
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        auditStatus: this.auditStatus,
        merchantName: this.SeachParams.merchantName,
        phone: this.SeachParams.phone,
        isEnable: this.SeachParams.isEnable,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.logisticsApplyList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.numView = {
            allCount: res.data.allCount,
            toAuditCount: res.data.toAuditCount,
            passAuditCount: res.data.passAuditCount,
            refuseAuditCount: res.data.refuseAuditCount,
          };
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
            this.SeachParams.disuseLoding();
          }
      });
    },
    remarksClosed() {
      this.RemarksShow = false
      this.masterNote = ""
    },
    // 弹出弹框
    handleBtn(row, type) {
      this.rowId = row.id
      if (type == 'remark') {
        if (row.masterNote) {
          this.RemarksTitle = '修改备注'
        } else {
          this.RemarksTitle = '添加备注'
        }
        this.masterNote = row.masterNote
        this.RemarksShow = true
      } else if (type == 'apply') {
        this.applyTitle = row.auditStatus == '01' ? '审核物流申请' : '审核记录'
        this.rowId = row.id
        this.applyRecord = {
          merchantName: row.merchantName,
          applyStaffName: row.applyStaffName,
          applyStaffPhone: row.applyStaffPhone,
          auditStatus: row.auditStatus,
          auditNote: row.auditNote,
          auditMasterName: row.auditMasterName,
          auditTime: row.auditTime,
        }
        this.applyDialogVisible = true
      }
    },
    // 备注确定提交
    remarksSure() {
      this.butLoading = true
      _api.logisticsMasterNote({id: this.rowId, masterNote: this.masterNote}).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          this.RemarksShow = false
          this.handleCurrentChange()
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    qiClick(type){
       this.isLogisticsConfig=type
       this.handleCurrentChangeDialog(1)
    },
    btnClick(type) {
      this.auditStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    seeLogisticsSet(row) {
      this.getLogisticsConfigInfo(row.merchantId)
      this.setDialogVisible = true;
      this.setingData = {
        ...this.setingData,
        merchantId: row.merchantId
      }
    },
    setDialogClose() {
      this.setDialogVisible = false
    },
    //获取物流规则
    getLogisticsConfigInfo(merchantId) {
      _api.getLogisticsConfigInfo({merchantId}).then(res => {
        if (res.code === 1) {
          this.rulesInfo = res.data;
          let expressList = [];
          // 判空
          this.expressList = Array.isArray(res.data.expressList) && res.data.expressList.length > 0 ? res.data.expressList : [{
            expressCode: '',
            expressName: "--",
            isFirst: false,
          }, {expressCode: '', expressName: "--", isFirst: false}]
          // 取未选择快递值
          const item = this.expressList.find(v => v.isFirst)
          // 存在push
          if (item) {
            expressList.push(item);
          }
          // 合并
          const otherData = this.expressList.filter(v => !v.isFirst);
          this.expressList = [...expressList, ...otherData];
          this.setingData = {
            ...this.setingData,
            // 不存在快递选择，也就是从未设置，取空
            firstExpressCode: item ? item.expressCode : null,
            minCostSend: res.data.minCostSend ?? null
          };
        }
      })
    },
    //弹出合作回收商家弹框
    seeBusiness(row) {
      this.rowId = row.id;
      this.currentData = row;
      this.businessVisible = true
      this.handleCurrentChangeDialog(1)
    },
    batchAction(){
      this.isbatch=true
      let title;
      let tipText;
      let headerMessage;
      let currentData = {companyId:"", isLogisticsConfig:!this.isLogisticsConfig};
      if (!this.isLogisticsConfig) {
        title = "启用提示";
        tipText = "是否确认启用物流？";
        headerMessage = "说明：启用物流后，门店可使用线上物流发货与该商家成交的近1个月的旧机";
      } else {
        title = "关闭提示";
        tipText = "是否确认关闭物流？";
        headerMessage = "说明：关闭物流后，门店将不可使用繁星物流发货";
      }
      this.logisticsSwitchProps = {
        ...this.logisticsSwitchProps,
        currentData,
        show: true,
        code: null,
        title,
        tipText,
        headerMessage
      };
    },
    // 合作商家开关
    companyIsEnableChange(isLogisticsConfig, row) {
      this.islistLogistics=isLogisticsConfig
      this.listLogisticData=row
      this.isbatch=false
      const currentData = {...row, isLogisticsConfig};
      const index = this.DialogDataList.findIndex(v => v.companyId === row.companyId);
      let title;
      let tipText;
      let headerMessage;
      if (isLogisticsConfig) {
        title = "启用提示";
        tipText = "是否确认启用物流？";
        headerMessage = "说明：启用物流后，门店可使用线上物流发货与该商家成交的近1个月的旧机";
      } else {
        title = "关闭提示";
        tipText = "是否确认关闭物流？";
        headerMessage = "说明：关闭物流后，门店将不可使用繁星物流发货";
      }
      this.logisticsSwitchProps = {
        ...this.logisticsSwitchProps,
        currentData,
        show: true,
        code: null,
        title,
        tipText,
        headerMessage
      };
      // 更改状态
      this.DialogDataList[index] = currentData;
    },
    // 切换开关确定操作
    logisticsSwitchConfig() {
      const {companyId, isLogisticsConfig} = this.logisticsSwitchProps.currentData;
      let request;
      const data = {
        companyIds: [],
        merchantId: this.currentData.merchantId,
      }
      if(this.isbatch){
        data.companyIds=this.logMerchantList.map(item=>item.companyId)
      }else{
        data.companyIds.push(companyId)
      }
      if (isLogisticsConfig) {
        request = _api.addLogisticsCompany;
      } else {
        request = _api.removeAddressCompany;
      }
      request(data).then(r => {
        this.logisticsSwitchProps = {
          ...this.logisticsSwitchProps,
          show: false,
        }
        this.$message.success("操作成功")
        this.handleCurrentChangeDialog();
        this.handleCurrentChange();
      });
    },
    //合作回收商家
    handleCurrentChangeDialog(val) {
      this.DialogDataList = [];
      if (val) {
        this.dialogPage.pageNum = val;
      }

      const baseRequest = {
        companyName: this.companyName,
        isLogisticsConfig: this.isLogisticsConfig,
        id: this.rowId,
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
      };
      this.businessLoading = true;
      _api.getUnionCompanyList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.dialogPage.pageNum = res.data.current;
          this.dialogPage.total = res.data.total;
          this.businessLoading = false;
        }
      });
    },
    searchSetting() {
      this.handleCurrentChangeDialog(1)
    },
    onClose() {
      this.businessVisible = false
    },
    enableClosed() {
      this.command = ""
      this.handleCurrentChange()
    },
    enableCancel() {
      this.enableShow = false
    },
    handleSwitch(row) {
      this.enableTitle = row.isEnable ? '开启物流提示' : '关闭物流提示'
      this.isEnable = row.isEnable
      this.rowId = row.id
      this.enableShow = true
    },
    // 禁用弹框确认按钮
    enableSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令');
        return
      }
      let params = {
        command: this.command,
        id: this.rowId,
        isEnable: this.isEnable
      }
      this.enableButLoading = true
      _api.enableLogistics(params).then(res => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.enableShow = false
        } else {
          this.$message.error(res.msg || '操作失败');
        }
        this.enableButLoading = false
      }).catch(err => {
        this.enableButLoading = false
      })
    },
    // 审核弹框关闭
    applyDialogClose() {
      this.command = ""
      this.auditStatusRadio = ""
      this.monthSettlementAccount = ""
      this.auditNote = ""
      this.applyDialogVisible = false
    },
    // 审核确认按钮
    applyHandleSuccess() {
      if (!this.auditStatusRadio) {
        this.$message.error('请选择审核结果');
        return
      }
      if (this.auditStatusRadio == '02' && !this.monthSettlementAccount) {
        this.$message.error('请输入月结账号');
        return
      }
      if (!this.command) {
        this.$message.error('请输入动态口令');
        return
      }
      let params = {
        auditNote: this.auditNote,
        id: this.rowId,
        auditStatus: this.auditStatusRadio,
        monthSettlementAccount: this.auditStatusRadio == '02' ? this.monthSettlementAccount : '',
        command: this.command
      }
      _api.logisticsAudit(params).then(res => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.handleCurrentChange()
          this.applyDialogVisible = false
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/ .el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 500px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981FF;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.apply_for_tip {
  margin-bottom: 20px;
  transform: translateX(-10px);
}

/deep/ .apply_for_box .el-dialog .el-dialog__body {
  padding-left: 30px;
  padding-right: 30px;
}

.enable_box {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 6px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 6px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }

  /deep/ .el-switch__core {
    background: #B6B9D0;
  }

  /deep/ .el-switch.is-checked .el-switch__core {
    background-color: #0981FF
  }
}

.logistics-switch-dialog {
  /deep/ .header_messgae {
    margin-bottom: 34px;
    color: #FF7586;
  }

  /deep/ .tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
}
.tip {
  font-size: 14px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  span{
    color: rgb(9, 129, 255)
  }
}
</style>
